const config = {
    emailSubject: 'Immediate Action Needed: Permanent Ceasefire in Gaza Now',
    emailBodyTemplate: (state, zipCode) => `I am writing to you as a concerned constituent from ${state}, zip code ${zipCode}. With the recent end of the temporary ceasefire in Gaza, the need for a permanent resolution is more urgent than ever. I urge you to advocate for a permanent ceasefire and an end to the ongoing siege on Gaza. The safety and well-being of millions, including children, are at critical risk.

This communication is an urgent call for the U.S. to play a proactive and meaningful role in transforming this fragile situation into a lasting peace. Only a permanent solution can ensure the stability and safety of all parties involved.

My vote in upcoming elections will depend on your stance and actions on this vital issue. Your support for a permanent ceasefire and an end to the occupation is imperative.

I am advocating for the following legislation and expect you to vote for and co-sponsor these bills, now or when matching legislation reaches your office.
- H.Res. 786 by Rep. Cori Bush
- H.Res. 388 by Rep. Rashida Tlaib
- H.R. 3103 by Rep. Betty McCollum

Additionally, I urge you to consider the global call for a permanent ceasefire, as evidenced by these open letters signed by individuals and organizations worldwide:
- Plan International Open Letter for a Ceasefire, signed by 500 orgs and representatives from 60 countries—https://plan-international.org/news/2023/10/20/open-call-for-ceasefire-in-gaza/
- An Open Letter from Congressional Staff—https://www.documentcloud.org/documents/24041700-hill-staff-ceasefire-open-letter
- Philanthropy Open Letter for Humanity and Justice—https://funders4ceasefire.org/index
- An Open Letter From 750 North American Rabbis and Cantors Responding to the Crisis in Israel and Gaza—https://truah.org/an-open-letter-from-north-american-rabbis-and-cantors-responding-to-the-crisis-in-israel-and-gaza/
- Artists 4 Ceasefire:—https://www.artists4ceasefire.org/

Your actions in Congress are crucial in shaping a sustainable future for all people in the region. I implore you to use your position to advocate for a lasting peace, including a permanent ceasefire and an end to the siege on Gaza.

Thank you for your attention to this critical matter. I look forward to your response and to witnessing your decisive actions in support of a lasting peace in Gaza.`,
    getRepresentativesApiUrl: 'https://ztjmovic05.execute-api.us-east-1.amazonaws.com/dev/get-representatives'
};

export default config;