import React from 'react';
import { Box, Heading, Text, VStack, Divider } from '@chakra-ui/react';

const CampaignContentAfter = () => {
    return (
        <VStack spacing={4} align="stretch">
            <Box>
                <Heading size="lg" mb={4}>Ceasefire Now - Permanently - Email Your Congressmen in Just 10 Seconds</Heading>
                <Text fontSize="md">Demand a <strong>Permanent</strong> Ceasefire in Gaza and End U.S. Complicity in Oppression</Text>
            </Box>

            <Divider />

            <Box>
                <Heading size="md" mb={2}>Urgent Humanitarian Crisis in Gaza Intensifies:</Heading>
                <Text fontSize="md">
                    With the recent end of the temporary ceasefire, the crisis in Gaza has escalated. More than half of its two million residents, including many children, continue to face severe shortages of food, water, electricity, and medical supplies. In this critical time, your advocacy through emails is vital for their rights and safety.
                </Text>
            </Box>

            <Heading size="md" mt={6}>How It Works</Heading>
            <Text><strong>1. Enter Your Details:</strong> Provide your zip code and state to identify your senators and local House representative.</Text>
            <Text><strong>2. Review Your Message:</strong> A pre-drafted email will be prepared, urging your representatives to support the Ceasefire Now resolution.</Text>
            <Text><strong>3. Send with Your Email Client:</strong> Click "Submit" to open your email application and send the message from your account.</Text>
            <Text><strong>4. Make Your Voice Heard:</strong> We encourage you to follow up on the response from your representatives.</Text>

        </VStack>
    );
};

export default CampaignContentAfter;
