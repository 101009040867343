// src/CampaignContentAfter.js
import React from 'react';
import { Heading, VStack, Box, Button, Center, Link, Text } from '@chakra-ui/react';
import { FaTwitter } from 'react-icons/fa'; // Import Font Awesome Twitter icon

const ShareOnTwitter = () => {
    const tweetText = encodeURIComponent("Join me in demanding a permanent ceasefire in Gaza. It takes just 10 seconds at https://ceasefire-now.com #CeasefireNOW #PermanentPeaceInGaza");
    const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

    return (
        <Box as="footer" role="contentinfo" mx="auto" maxW="7xl" py="3" px={{ base: '4', md: '8' }}>
            <Center>
                <VStack>
                    <Text>Share on</Text>
                    <Link href={tweetUrl} isExternal>
                        <Button leftIcon={<FaTwitter />} colorScheme="blue" variant="solid">
                            Twitter
                        </Button>
                    </Link>
                </VStack>
            </Center>
        </Box>
    );
};

const CampaignContentAfter = () => {
    return (
        <VStack spacing={4} align="stretch">
            <Heading size="md" mt={6}>Our Focus</Heading>
            <Text>
                Ceasefire Now is urgently committed following the end of the temporary ceasefire. Our advocacy against violence in Gaza is more crucial than ever as we push for a permanent, peaceful resolution.
            </Text>

            <Heading size="md" mt={6}>Why Participate?</Heading>
            <Text>
                <strong>Influence Policy:</strong> Your emails are a powerful tool for shaping the decisions of elected officials. By participating, you contribute directly to a national call for peace, urging leaders to prioritize a ceasefire in Gaza.
            </Text>
            <Text>
                <strong>Express Your Views:</strong> Use your voice to highlight the urgency of the crisis. Your concerns, when aggregated, become a significant force, compelling officials to acknowledge and address the situation.
            </Text>
            <Text>
                <strong>Hold Officials Accountable:</strong> Continuous engagement is key. Your repeated emails serve as a reminder that the public's demand for peace and justice is not a fleeting moment, but a sustained movement. By maintaining pressure, you ensure that officials remain responsive to their constituents' calls for a ceasefire.
            </Text>

            <Heading size="md" mt={6}>Impact of Your Voice</Heading>
            <Text>
                While we may not reach representatives directly, the aggregate numbers of emails sent make an undeniable impact. The volume of messages calling for a ceasefire is reported to officials, illustrating the strength and persistence of public opinion.
            </Text>
            <Text>
                It's essential to continue sending emails, repeatedly if necessary, to ensure our message is heard loud and clear. Representatives might expect public interest to wane, but our sustained efforts will demonstrate the enduring commitment of the community to this cause.
            </Text>

            <Heading size="md" mt={6}>A Call for Compassion</Heading>
            <Text>
                As UNICEF states, "Compassion — and international law — must prevail." Join us in sending a unified message against the tragedy unfolding in Gaza. Your participation is vital in urging the U.S. to take a stand against this crisis.
            </Text>

            <Heading size="md" mt={6}>Participate Now</Heading>
            <Text>
                Join a national movement demanding change in U.S. foreign policy. Raise your voice. Demand action. Together, we can make a difference and contribute to a future where peace prevails.
            </Text>

            <ShareOnTwitter />

            <Heading size="md" mt={6}>Raise your voice. Demand action. Let’s turn this pause into lasting peace.</Heading>
        </VStack>
    );
};

export default CampaignContentAfter;
